import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import SiteMetadata from "../components/SiteMetadata";

import Layout from "../components/Layout";
import Features from "../components/features/Features";
import FullWidthImage from "../components/fullWidthImage/FullWidthImage";

import call from '../img/call.svg'

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import { AiFillCheckCircle } from 'react-icons/ai';

import * as style from './index-page.module.css'
import ContactForm from "../components/contactForm/ContactForm";
import GoogleMaps from "../components/GoogleMaps";

import Reveal from '../components/Reveal'

import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import { pdfURL } from "../helpers/helper";

// eslint-disable-next-line
export const IndexPageTemplate = ({
  image,
  imageRespo,
  title,
  montage,
  subheading,
  callInfo,
  service,
  intro,
  prices,
  aboutus,
  metadata,
  katalog,
  cennik,
  heat_pumps
}) => {
  
  const heroImage = getImage(image) || image;
  const heroImageRespo = getImage(imageRespo) || image;
  return (
    <>
      <SiteMetadata {...metadata} />
    
      <div>
        <FullWidthImage img={heroImage} imgRespo={heroImageRespo} title={title} subheading={subheading} />        
        <section id="aboutus" className="section">
          <div className="container-lg">
            <div id="blocks">
              <Features links={[katalog, heat_pumps, cennik]} gridItems={intro.blocks} />
            </div>
          </div>
          <div className="container">
            <Reveal>
              <div className="row">
                <h2 className="title-2 text-center text-md-left">{aboutus.title}</h2>
                <p className="description">{aboutus.description}</p>
              </div>
            </Reveal>
          </div>
        </section>

        {/* Montage */}
        <Reveal>
          <section id="montage" className={`row d-flex align-items-center container ${style.montage}`}>
            <div className="col-lg-4 offset-lg-1 pt-5 pb-5">
              <h2 className="title-2  text-center text-md-left">{montage.title}</h2>
              <p className="description">{montage.description}</p>
              <Link to="/udrzba" className="btn btn-lg btn-custom-green mt-4">Čítať viac</Link>
            </div>
            <div className="col-md-6 offset-1 d-none d-lg-block pr-0">
              <PreviewCompatibleImage imageInfo={{...montage.photo, alt: montage.title}} />
            </div>
          </section>
        </Reveal>

          <Reveal>
            <section id="service" className="section fix-width">
              <div className="container mb-5">
                <h2 className="title-2 text-center text-md-left">{service.title}</h2>
                <p className="description mb-5">{service.description}</p>
              </div>
              <AliceCarousel
                mouseTracking
                autoPlayInterval={5000}
                infinite
                responsive={{
                  0: { items: 1 },
                  500: { items: 2 },
                  961: { items: 3 },
                  1222: { items: 3 },
                  1555: { items: 4 },
                  1900: { items: 5 },
                  2200: { items: 6 },
                  2400: { items: 7 },
                }}
                disableDotsControls={false}
                disableButtonsControls={true}
                autoPlay={true}
              items={service.gallery.map((reference, i) => {
                  return (
                      <div className="item" key={'reference-carousel-item' + i}>
                        <PreviewCompatibleImage imageInfo={{image: reference.image, alt: reference.alt}} />
                      </div>
                    )
                })}
              />
              <div className="text-center mt-4">
                <Link to="/udrzba" className="btn btn-lg btn-custom-green mt-4">Čítať viac</Link>
              </div>
            </section>
          </Reveal>
      
          <Reveal>
              <section id="price" className="section">
                <div className="container">
                  <h2 className="title-2 text-center text-md-left">{prices.title}</h2>
                  <p className="description">{prices.description}</p>
                </div>
                <div className="container-lg">
                  <div className="row row-eq-height mt-5 justify-content-center">
                    {prices.items.map((price, key) => (
                      <div className={`col-4 ${style.price}`} key={key}>
                        <div className={`${style.priceInner}`}>
                          <div className={`text-center`}>
                            <h4 className={style.priceTitle}>{price.title}</h4>
                            <span className={style.priceDescription}>{price.description}</span>
                            <div className={`${style.pricePrice}`}>{ price.price }</div>
                          </div>
                          <div>
                            <ul className={`${style.priceList} text-left`}>
                              {price.list.map((item, itemKey) => (
                                <li className={style.priceListItem} key={itemKey}>
                                  <AiFillCheckCircle className={style.priceCheck} />
                                  <span>{item.title}</span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-auto justify-content-center">
                      <a href={`/img/${pdfURL(katalog)}`} target="blank" className="btn btn-lg btn-custom-primary mx-3">Katalóg</a>
                      <a href={`/img/${pdfURL(cennik)}`} target="blank" className="btn btn-lg btn-custom-green mx-3">Cenník</a>
                    </div>
                  </div>
                </div>
            </section>  
          </Reveal>

          <Reveal>
            <section id={'call'} className={`text-center ${style.call}`}>
              <div className={'container'}>
                <img src={call} className={style.callIcon} alt="call" />
                <h2>{callInfo.title}​</h2>
                <span className={style.callDescription}>{callInfo.description}​</span>

                <div className={style.callNumber}>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <span>{callInfo.number}</span>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </section>
          </Reveal >
            
        <Reveal>
          <section id={'contactForm'} className={`text-center section ${style.contactForm}`}>
            <h2 className="title-2 mb-5 pb-5">Kontakt</h2>
            <ContactForm />
            </section>
          </Reveal>

          <Reveal>
            <section id="map">
              <GoogleMaps />
            </section>
          </Reveal>
          
      </div>
    </>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  imageRespo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  prices: PropTypes.object,
  services: PropTypes.object,
  description: PropTypes.string,
  metadata: PropTypes.object,
  cennik: PropTypes.object,
  katalog: PropTypes.object,
  intro: PropTypes.shape({
    blocks: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        imageRespo={frontmatter.imageRespo}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        montage={frontmatter.montage}
        service={frontmatter.service}
        callInfo={frontmatter.callInfo}
        intro={frontmatter.intro}
        prices={frontmatter.prices}
        aboutus={frontmatter.aboutus}
        metadata={frontmatter.metadata}
        katalog={frontmatter.katalog}
        cennik={frontmatter.cennik}
        heat_pumps={frontmatter.heat_pumps}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        metadata {
          title
          description
        }
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
          }
        }
        imageRespo {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
          }
        }
        heading
        subheading
        intro {
          blocks {
            title
            text
            icon {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: CONSTRAINED, height: 70, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
        }
        aboutus {
          title
          description
        }
        montage {
          title
          description
          photo {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
        callInfo {
          title
          description
          number
        }
        service {
          title
          description
          gallery{
						image {
          		childImageSharp {
                gatsbyImageData(quality: 100, width:500, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF])
              }
        		}
            alt
          }
        }
        prices {
          title
          description
          items {
            title
            description
            price
            list {
              title
            }
          }
        }
        katalog {
          id
          base
          relativePath
        }
        heat_pumps {
          id
          base
          relativePath
        }
        cennik {
          id
          base
          relativePath
        }
      }
    }
  }
`;
