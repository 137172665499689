import React, { Component } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '600px'
};

const center = {
  lat: 48.1412342,
  lng: 17.3317783
};

class GoogleMaps extends Component {
  render() {
    return (
      <LoadScript
        googleMapsApiKey="AIzaSyDIdVJ7EHiEjotUdE2vMJlF3G9aQq1jGao"
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={17}
        >
          <Marker
          position={center}
        />
        </GoogleMap>
      </LoadScript>
    )
  }
}

export default GoogleMaps
