import * as React from "react";
import PropTypes from "prop-types";

import * as style from './Features.module.css';
import Tilt from 'react-tilt'
import { GatsbyImage } from "gatsby-plugin-image";
import { pdfURL } from "../../helpers/helper";

const FeatureGrid = ({ gridItems, links }) => (
  <div className={`row justify-content-center row-eq-height d-flex ${style.features}`}>
    {gridItems.map((item, key) => (
      <Tilt
          key={item.text} className={`col-xl-3 col-lg-4 mb-4 ${style.featureCont}`}
          options={{
            max: 10,
            glare: true,
            'max-glare': 0.1,
            reverse: true,
            scale: 1.05,
        }}>
          
        <div className={style.feature}>
          <a className="no-decoration" href={'/img/' + pdfURL(links[key])} target="_blank" rel="noreferrer">
            <div className={style.icon}>
              <GatsbyImage
                layout="constrained"
                image={item.icon.childImageSharp.gatsbyImageData}
                alt={item.title}
              />
            </div>
            <h3 className={style.title}>{item.title}</h3>
            <p className={`${style.description} mb-0`}>{item.text}</p>
            <div className={`${style.after}`}></div>
          </a>
        </div>
          
      </Tilt>
    ))}
  </div>
);
            
FeatureGrid.propTypes = {
  links: PropTypes.array,
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
      link: PropTypes.string,
    })
  ),
};

export default FeatureGrid;